import React from 'react';
import { FieldLocationAutocompleteInput, FieldTextInput } from '../../../components';
import FieldPhoneInput from '../../../components/FieldPhoneInput/FieldPhoneInput';
import css from './SignupForm.module.css';
import * as validators from '../../../util/validators';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { useEffect } from 'react';

export default function PerinatalCenterFields(props) {
  const { intl, form } = props;
  useEffect(() => {
    form.batch(() => {
      form.change('date', undefined);
      form.change('concieve', undefined);
      form.change('profession', undefined);
    });
  }, []);

  //  location
  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });
  return (
    <div>
      <FieldPhoneInput
        className={css.password}
        id="phoneNumber"
        name="phoneNumber"
        defaultCountry="BE"
        label={<FormattedMessage id="SignupForm.phoneNumberLabel" />}
        placeholder={intl.formatMessage({
          id: 'SignupForm.phoneNumberPlaceholder',
        })}
        validate={validators.composeValidators(
          validators.validatePhoneNumber(<FormattedMessage id="SignupForm.phoneNumberCorrect" />),
          validators.required(<FormattedMessage id="SignupForm.phoneNumberRequired" />)
        )}
      />
      <FieldLocationAutocompleteInput
        rootClassName={css.locationAddress}
        inputClassName={css.locationAutocompleteInput}
        iconClassName={css.locationAutocompleteInputIcon}
        predictionsClassName={css.predictionsRoot}
        validClassName={css.validLocation}
        name="Location"
        label={<FormattedMessage id="SignupForm.locationLabel" />}
        placeholder={intl.formatMessage({
          id: 'SignupForm.locationPlaceholder',
        })}
        useDefaultPredictions={false}
        validate={validators.composeValidators(
          validators.autocompleteSearchRequired(addressRequiredMessage),
          validators.autocompletePlaceSelected(addressNotRecognizedMessage)
        )}
        // hideIcon={true}
      />
      <FieldTextInput
        className={css.password}
        id="room"
        name="room"
        type="number"
        min="1"
        label={<FormattedMessage id="SignupForm.roomLabel" />}
        placeholder={intl.formatMessage({
          id: 'SignupForm.roomPlaceholder',
        })}
        validate={validators.composeValidators(
          validators.required(<FormattedMessage id="SignupForm.roomRequired" />),
          validators.invalidNumber(<FormattedMessage id="SignupForm.roomNegative" />)
        )}
      />
    </div>
  );
}
