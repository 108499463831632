import React, { useState, useEffect } from 'react';
import { FieldCheckbox } from '../../../components';
import css from './SignupForm.module.css';
import { concieve, newsletter, withLinks } from '../../../config/configListing';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

import FieldDateInput from '../../../components/FieldDateInput/FieldDateInput';
import { SET_DISABLE } from '../../../ducks/auth.duck';
import { useDispatch } from 'react-redux';
import FieldPhoneInput from '../../../components/FieldPhoneInput/FieldPhoneInput';

export default function(props) {
  const { intl, form, values } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.concieve !== false) form.change('date.date', null);
    dispatch({
      type: SET_DISABLE,
      payload: values?.date?.date !== null || values.concieve !== false ? false : true,
    });
  }, [values.concieve]);
  useEffect(() => {
    if (values?.date?.date !== null) form.change('concieve', false);
    dispatch({
      type: SET_DISABLE,
      payload: values?.date?.date !== null || values.concieve !== false ? false : true,
    });
  }, [values?.date?.date]);

  useEffect(() => {
    form.batch(() => {
      form.change('phoneNumber', undefined);
      form.change('Location', undefined);
      form.change('postal', undefined);
      form.change('room', undefined);
      form.change('profession', undefined);
    });
  }, []);

  return (
    <div>
      <div className={css.concieve}>
        <FieldPhoneInput
          className={css.password}
          id="phoneNumber"
          defaultCountry="BE"
          name="phoneNumber"
          label={<FormattedMessage id="SignupForm.phoneNumberLabel" />}
          placeholder={intl.formatMessage({
            id: 'SignupForm.phoneNumberPlaceholder',
          })}
          validate={validators.composeValidators(
            validators.validatePhoneNumber(<FormattedMessage id="SignupForm.phoneNumberCorrect" />),
            validators.required(<FormattedMessage id="SignupForm.phoneNumberRequired" />)
          )}
        />
        <FieldDateInput
          id="date"
          name="date"
          label={<FormattedMessage id="SignupForm.dateLabel" />}
          placeholderText={intl.formatMessage({ id: 'SignupForm.datePlaceHolder' })}
          isParentSignup={true}
        />
      </div>

      <div className={css.concieve}>
        <FieldCheckbox
          id={concieve.id}
          name={concieve.name}
          label={<FormattedMessage id={concieve.label} />}
        />
      </div>
      <div className={css.newsletter}>
        <FieldCheckbox
          id={newsletter.id}
          name={newsletter.name}
          label={<FormattedMessage id={newsletter.label} />}
          value={newsletter.value}
        />
      </div>
    </div>
  );
}
