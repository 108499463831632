import React from 'react';
import { func } from 'prop-types';

import { requiredFieldArrayCheckbox } from '../../../util/validators';
import { FieldCheckboxGroup, ExternalLink } from '../../../components';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';

import css from './TermsAndConditions.module.css';
import { useSelector } from 'react-redux';

const KEY_CODE_ENTER = 13;

const TermsAndConditions = props => {
  const { onOpenTermsOfService, onOpenPrivacyPolicy, onOpenCharteParents, onOpenCharteProfessionals, intl } = props;
const state=useSelector(state=>state.auth)
const {usertype}=state
  const handleClick = callback => e => {
    e.preventDefault();
    callback(e);
  };
  const handleKeyUp = callback => e => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      callback();
    }
  };
  const user= (usertype==="professional"? (<span
    className={css.termsLink}
    onClick={handleClick(onOpenCharteProfessionals)}
    role="button"
    tabIndex="0"
    onKeyUp={handleKeyUp(onOpenCharteProfessionals)}
  >
    <FormattedMessage id="SignupForm.usertypeProfessionals" />
  </span>): (<span
      className={css.termsLink}
      onClick={handleClick(onOpenCharteParents)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenCharteParents)}
    >
      <FormattedMessage id="SignupForm.usertypeParents" />
    </span>
  )
  )


  const termsLink = (
    <span
      className={css.termsLink}
      onClick={handleClick(onOpenTermsOfService)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenTermsOfService)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </span>
  );
  const termsUse = (
    <span
      className={css.termsLink}
      onClick={handleClick(onOpenTermsOfService)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenTermsOfService)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsUseLinkText" />
    </span>
  );

  const privacyLink = (
    <span
      className={css.privacyLink}
      onClick={handleClick(onOpenPrivacyPolicy)}
      role="button"
      tabIndex="0"
      onKeyUp={handleKeyUp(onOpenPrivacyPolicy)}
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </span>
  );
 

  return (
    <div className={css.root}>
      <FieldCheckboxGroup
        name="terms"
        id="terms-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos-and-privacy',
            label:usertype==="perinatalCenter"? intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptTextPerinatalCenter' },
              { termsLink, privacyLink,termsUse,user}
            ): intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptText' },
              { termsLink, privacyLink,termsUse,user}
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
        )}
      />
    </div>
  );
};

// TermsAndConditions.defaultProps = {};

TermsAndConditions.propTypes = {
  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
