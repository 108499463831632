import React from 'react';
import { newsletter, withLinks } from '../../../config/configListing';
import * as validators from '../../../util/validators';
import FieldPhoneInput from '../../../components/FieldPhoneInput/FieldPhoneInput';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { useEffect } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

import css from './SignupForm.module.css';
import { FieldCheckbox, FieldLocationAutocompleteInput, FieldTextInput } from '../../../components';
const identity = v => v;
export default function ProfessionalFields(props) {
  const { intl, form } = props;

  useEffect(() => {
    form.batch(() => {
      form.change('date', undefined);
      form.change('concieve', undefined);
      form.change('room', undefined);
    });
  }, []);

  //  location
  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });
  return (
    <div>
      <FieldPhoneInput
        className={css.password}
        id="phoneNumber"
        defaultCountry="BE"
        name="phoneNumber"
        label={<FormattedMessage id="SignupForm.phoneNumberLabel" />}
        placeholder={intl.formatMessage({
          id: 'SignupForm.phoneNumberPlaceholder',
        })}
        validate={validators.composeValidators(
          validators.validatePhoneNumber(<FormattedMessage id="SignupForm.phoneNumberCorrect" />),
          validators.required(<FormattedMessage id="SignupForm.phoneNumberRequired" />)
        )}
      />
      <FieldLocationAutocompleteInput
        rootClassName={css.locationAddress}
        inputClassName={css.locationAutocompleteInput}
        iconClassName={css.locationAutocompleteInputIcon}
        predictionsClassName={css.predictionsRoot}
        validClassName={css.validLocation}
        name="Location"
        label={<FormattedMessage id="SignupForm.addressLabel" />}
        placeholder={intl.formatMessage({
          id: 'SignupForm.addressPlaceholder',
        })}
        useDefaultPredictions={false}
        format={identity}
        validate={validators.composeValidators(
          validators.autocompleteSearchRequired(addressRequiredMessage),
          validators.autocompletePlaceSelected(addressNotRecognizedMessage)
        )}
        // hideIcon={true}
      />
      <FieldTextInput
        className={css.password}
        id="profession"
        name="profession"
        label={<FormattedMessage id="SignupForm.professionLabel" />}
        placeholder={intl.formatMessage({
          id: 'SignupForm.professionPlaceholder',
        })}
        type="text"
        validate={validators.composeValidators(
          validators.required(<FormattedMessage id="SignupForm.professionRequired" />),
          validators.maxLength(<FormattedMessage id="SignupForm.professionMaxLength" />, 50)
        )}
      />

      <div className={css.newsletter}>
        <FieldCheckbox
          id={newsletter.id}
          name={newsletter.name}
          label={<FormattedMessage id={newsletter.label} />}
          value={newsletter.value}
        />
      </div>
    </div>
  );
}
